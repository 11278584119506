/**
 * This class provides functions to control the game search.
 *
 * @constructor
 */
function ProfileGames() {

	/**
	 * The time until the search request is triggered.
	 *
	 * @type {number}
	 */
	const AUTOCOMPLETE_WAIT_TIMEOUT = 500;


	/**
	 * The instance of this class.
	 *
	 * @type {ProfileGames}
	 */
	let self = this;

	/**
	 * The timeout object.
	 *
	 * @type {?number}
	 */
	this.timeout = null;

	/**
	 * The jqXHR object form the current search request.
	 *
	 * @type {?object}
	 */
	this.jqXHRSearch = null;

	/**
	 * The array with game IDs that are currently have pending add request.
	 *
	 * @type {Array}
	 */
	this.fetchingAddGameIDs = [];

	/**
	 * The array with game IDs that are currently have pending delete request.
	 *
	 * @type {Array}
	 */
	this.fetchingDeleteGameIDs = [];

	/**
	 * The status variable for the custom user game request.
	 *
	 * @type {boolean}
	 */
	this.customGameRequestPending = false;


	/**
	 * The ProfileGames class constructor.
	 */
	this.init = function() {

		$(document).on('keyup', '#gameName', self.searchGames);
		$(document).on('click', '[data-add-game]', self.addGame);
		$(document).on('click', '[data-delete-game]', self.deleteGame);
		$(document).on('click', '#addUserGame', self.addUserGame);
	};

	/**
	 * Gets the games based on the user input query.
	 */
	this.searchGames = function() {

		if(self.timeout !== null) {
			window.clearTimeout(self.timeout);
		}

		let handle = this;
		self.timeout = window.setTimeout(function() {
			self.fetchSearchResults(handle);
		}, AUTOCOMPLETE_WAIT_TIMEOUT);
	};

	/**
	 * Get the search results for the user query.
	 *
	 * @param handle
	 */
	this.fetchSearchResults = function(handle) {

		let container = $('#gameResults');
		let query = $(handle).val();

		if(self.jqXHRSearch !== null) {
			self.jqXHRSearch.abort();
		}

		container.html('<div class="row align-items-center table-row"><div class="col-sm-10"><i class="fa fa-spin fa-spinner"></i> ' + tex('global.loading_data') + '</div></div>');

		self.jqXHRSearch = $.ajax({
			method: 'POST',
			url: '/ajax/games/autocomplete',
			data: { query: query, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if (_.isObject(obj) === true) {
				if (_.has(obj, 'status') === true && _.has(obj, 'data') === true) {
					if (obj.status === 1) {

						if(_.isEmpty(query) === false) {
							container.html(obj.data);
						}
						else {
							container.html('');
						}
					}
					else {
						container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_STATUS)');
					}
				}
				else {
					container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
				}
			}
			else {
				container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_NO_OBJ)');
			}
		})
		.fail(function(jqXHR, textStatus, errorThrown) {
			if(errorThrown !== 'abort') {
				container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_CONN_FAIL)');
			}
		})
		.always(function() {
			self.jqXHRSearch = null;
		});
	};

	/**
	 * Adds an game to the users library.
	 */
	this.addGame = function() {

		let gameRowObj = $(this);
		let gameID = gameRowObj.data('game-id');

		if(_.contains(self.fetchingAddGameIDs, gameID) === true) {
			return;
		}

		let oldIconClasses = gameRowObj.find('i').attr('class');

		self.fetchingAddGameIDs.push(gameID);
		gameRowObj.find('i').attr('class', 'fa fa-spinner fa-spin');

		$.ajax({
			method: 'POST',
			url: '/ajax/games/add',
			data: { id: gameID, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'msg') === true) {
					if(obj.status === 1) {
						gameRowObj.find('i').attr('class', 'fa fa-check color-green');

						$('[data-game-list-empty]').remove();
						let gameListObj = $('#gameList');
						gameListObj.prepend(obj.msg);
					}
					else {
						alert(obj.msg);
						gameRowObj.find('i').attr('class', oldIconClasses);
					}
				}
				else {
					alert(tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
					gameRowObj.find('i').attr('class', oldIconClasses);
				}
			}
			else {
				alert(tex('error.internal_error') + ' (ERR_NO_OBJ)');
				gameRowObj.find('i').attr('class', oldIconClasses);
			}
		})
		.fail(function(jqXHR, textStatus, errorThrown) {
			if(errorThrown !== 'abort') {
				alert(tex('error.internal_error') + ' (ERR_CONN_FAIL)');
			}

			gameRowObj.find('i').attr('class', oldIconClasses);
		})
		.always(function() {
			self.fetchingAddGameIDs = _.without(self.fetchingAddGameIDs , _.findWhere(self.fetchingAddGameIDs, gameID));
		});
	};

	/**
	 * Adds a custom game to the users library.
	 */
	this.addUserGame = function() {

		if(self.customGameRequestPending === true) {
			return;
		}

		self.customGameRequestPending = true;

		let buttonObj = $(this);
		let loadingObj = $('#addCustomGameLoading');
		let successObj = $('#addCustomGameSuccess');
		let gameNameObj = $('#userGameName');
		let gamePlatformObj = $('#gamePlatform');
		let gameName = gameNameObj.val();
		let gamePlatform = gamePlatformObj.val();

		loadingObj.removeClass('d-none').addClass('d-block');
		buttonObj.hide();
		successObj.hide();

		$.ajax({
			method: 'POST',
			url: '/ajax/games/add-custom',
			data: { custom: 1, name: gameName, platform: gamePlatform, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'msg') === true) {
					if(obj.status === 1) {
						gameNameObj.val('');
						successObj.removeClass('d-none').show();

						$('[data-game-list-empty]').remove();
						let gameListObj = $('#gameList');
						gameListObj.prepend(obj.msg);
					}
					else {
						alert(obj.msg);
					}
				}
				else {
					alert(tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
				}
			}
			else {
				alert(tex('error.internal_error') + ' (ERR_NO_OBJ)');
			}
		})
		.fail(function(jqXHR, textStatus, errorThrown) {
			if(errorThrown !== 'abort') {
				alert(tex('error.internal_error') + ' (ERR_CONN_FAIL)');
			}
		})
		.always(function() {
			self.customGameRequestPending = false;

			loadingObj.removeClass('d-block').addClass('d-none');
			buttonObj.show();
		});
	};

	/**
	 * Deletes the given game from the user library.
	 */
	this.deleteGame = function() {

		let gameRowObj = $(this);
		let gameID = gameRowObj.data('game-id');

		if(_.contains(self.fetchingDeleteGameIDs, gameID) === true) {
			return;
		}

		let oldIconClasses = gameRowObj.find('i.delete-icon').attr('class');

		self.fetchingDeleteGameIDs.push(gameID);
		gameRowObj.find('i.delete-icon').attr('class', 'delete-icon fa fa-spinner fa-spin');

		$.ajax({
			method: 'POST',
			url: '/ajax/games/delete',
			data: { id: gameID, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'msg') === true) {
					if(obj.status === 1) {
						gameRowObj.parent().parent().remove();

						let gameListObj = $('#gameList');
						if(gameListObj.children().length === 0) {
							gameListObj.html('<div data-game-list-empty class="row align-items-center table-row"><div class="col-12"><i class="fa fa-info"></i> ' + tex('games.no_games') + '</div></div>');
						}

					}
					else {
						alert(obj.msg);
						gameRowObj.find('i.delete-icon').attr('class', oldIconClasses);
					}
				}
				else {
					alert(tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
					gameRowObj.find('i.delete-icon').attr('class', oldIconClasses);
				}
			}
			else {
				alert(tex('error.internal_error') + ' (ERR_NO_OBJ)');
				gameRowObj.find('i.delete-icon').attr('class', oldIconClasses);
			}
		})
		.fail(function(jqXHR, textStatus, errorThrown) {
			if(errorThrown !== 'abort') {
				alert(tex('error.internal_error') + ' (ERR_CONN_FAIL)');
			}

			gameRowObj.find('i.delete-icon').attr('class', oldIconClasses);
		})
		.always(function() {
			self.fetchingDeleteGameIDs = _.without(self.fetchingDeleteGameIDs , _.findWhere(self.fetchingDeleteGameIDs, gameID));
		});
	};


	this.init();
}