/**
 * Loads an instance of the CKEditor for the given DOM ID.
 *
 * @param selector The DOM ID.
 */
function initCKEditor(selector) {

	let obj = $('#' + selector);
	if(_.isEmpty(obj) === false) {

		let hasAutoFocus = obj.is('[autofocus]');
		CKEDITOR.replace(selector, {
			customConfig: '/assets/js/ckeditor/config.js',
			lang: 'de',
			skin: 'ramelias-rambo-truppe',
			height: 300,
			extraPlugins: 'videodetector,autosave,emojione,colorbutton',
			startupFocus: hasAutoFocus,
			autosave: {
				messageType: "statusbar",
				delay: 5,
				autoLoad: true,
				saveDetectionSelectors: '[type="submit"]',
			},
			removeDialogTabs: 'image:Link;image:advanced;link:advanced;link:target;table:advanced',
			autosave_delay: 1,
			removePlugins: 'scayt,showborders,stylescombo,wsc,scayt,elementspath,iframe',
			removeButtons: 'Anchor,BGColor,Font',
			extraAllowedContent: 'blockquote[*](*);span[*](*);iframe[*](*);div[*](*)',
			disableObjectResizing: true,
			fontSize_sizes: "30/30%;50/50%;100/100%;120/120%;150/150%;200/200%;300/300%",
			format_tags: 'h2;h3;h4;h5;h6',
			uiColor: '#0d2e26',
			contentsCss: '/assets/css/ckeditor.css?20180822_0006',
			toolbar: [
				{
					name: 'basicstyles',
					items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
				},
				{
					name: 'paragraph',
					items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
				},
				{name: 'colors', items: ['TextColor']},
				{name: 'styles', items: ['FontSize', 'Format']},
				{name: 'links', items: ['Link', 'Unlink']},
				{
					name: 'paragraph',
					items: ['BulletedList', 'NumberedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
				},
				{name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'VideoDetector', 'Emojione']},

				{
					name: 'basicstyles,clipboard',
					items: ['CopyFormatting', 'RemoveFormat', '-', 'Undo', 'Redo']
				}
			]
		});
	}
}