let CSRF_TOKEN = '';
let select2DefaultOptions = {
	minimumResultsForSearch: -1
};
let ACTIVE_FORUM_VOTE_REQUESTS = [];

/**
 * The main class of the JavaScript.
 *
 * @constructor
 */
function Main() {

	/**
	 * @type {string} The name of the CKEditor instance.
	 */
	const FORUM_EDITOR_NAME = 'threadText';


	/**
	 * @type {Main} The instance of this class.
	 */
	let self = this;


	/**
	 * @type {boolean} If True the AJAX equesting is proccessing another
	 *     subscribe request.
	 */
	this.threadSubscribeFetching = false;


	/**
	 * The Main constrcutor.
	 */
	this.init = function() {

		window.setInterval(self.loadTSViewerData, 30000);

		$('input[type="checkbox"]').dcCheckbox();
		$('input[type="radio"]').dcRadioButton();
		$('[data-toggle="tooltip"]').tooltip();

		let userMenuObj = self.userMenu();
		if(userMenuObj.hasClass('guest-container') === true) {
			self.guestController();
		}
		else {
			self.userController();
		}

		$('select').not('.select2-hidden-accessible').select2(select2DefaultOptions);

		initFunction('#tsViewer', self.loadTSViewerData);
		initFunction('[data-init-cropper]', self.loadCropper);
		initFunction('#profileTab', self.initProfileJS);
		initFunction('#appointmentGame', self.initCalendarAutocomplete);
		initFunction('.lightbox-parent', self.initLightbox);
		initFunction('#pmUserSearch', self.initPMAutocomplete);

		// Register on click events
		self.registerOnClick('[data-sorting-header]', self.sortingDropdown);
		self.registerOnClick('#cookieComplianceBtn', self.setCookieCompliance);
		self.registerOnClick('#userDateOfBirthReset', self.resetDateOfBirth);
		self.registerOnClick('#sidebarToggle', self.sidebarToggle);
		self.registerOnClick('[data-toggle-appointment-option]', self.toggleAppointmentOptions);
		self.registerOnClick('[data-remove-participant]', self.removeParticipant);
		self.registerOnClick('[data-invite-accept]', self.acceptAppointmentInvite);
		self.registerOnClick('[data-invite-tentative]', self.tentativeAppointmentInvite);
		self.registerOnClick('[data-invite-decline]', self.declineAppointmentInvite);
		self.registerOnClick('[data-copy-clipboard]', self.copyToClipboard);
		self.registerOnClick('[data-select-value]', self.selectInputValue);

		self.initDatePickers();

		let appointmentTypeObj = $('[data-toggle-appointment-option]:checked');
		if(appointmentTypeObj.length > 0) {
			self.initAppointmentAutocomplete(appointmentTypeObj);
		}
	};

	/**
	 * Register for an click event with a event handler.
	 *
	 * @param {string} selector The selector.
	 * @param {object} callback The callback.
	 */
	this.registerOnClick = function(selector, callback) {
		$(document).on('click', selector, callback);
	};

	/**
	 * The controller for guest operations.
	 */
	this.guestController = function() {
		new Login();
	};

	/**
	 * The controller for logged in user operations.
	 */
	this.userController = function() {

		initClass('#shoutbox', Shoutbox);
		initClass('#gameServerManager', GameServerManager);
		this.initCKEditors();

		new Upload('#forumPostDND', '/ajax/upload/file/', 'copy-link');
		new Upload('#galleryUploadDND', '/ajax/galerie/upload/', 'gallery-image');

		// Set delete forum post modal ID and text
		setModalDataToPrimaryBtn('deletePostModal', 'post-id');
		$('#deletePostModal').on('show.bs.modal', self.deletePostModalSetText);

		// Set delete gallery image modal ID and text
		setModalDataToPrimaryBtn('deleteGalleryImageModal', 'upload-id');
		setModalDataToPrimaryBtn('deleteGalleryImageModal', 'thumbnail');
		$('#deleteGalleryImageModal').on('show.bs.modal', self.deleteGalleryImageSetText);

		// Set delete gallery image modal ID and text
		setModalDataToPrimaryBtn('deleteGalleryAlbumModal', 'album-id');

		self.registerOnClick('#navbarToggler', self.hideUserMenuOnMainMenuClick);
		self.registerOnClick('[data-notification-delete]', self.deleteNotification);
		self.registerOnClick('#deleteAllNotifications', self.deleteAllNotifications);
		self.registerOnClick('#deleteProfileImageBtn', self.deleteProfileImage);
		self.registerOnClick('#postPreviewBtn', self.postPreview);
		self.registerOnClick('#deleteForumPostBtn', self.deletePost);
		self.registerOnClick('#deleteAppointmentBtn', self.deleteAppointment);
		self.registerOnClick('#deleteCommentBtn', self.deleteComment);
		self.registerOnClick('[data-thread-subscribe]', self.threadSubscribe);
		self.registerOnClick('#deleteGalleryImageBtn', self.deleteGalleryImage);
		self.registerOnClick('#deleteGalleryAlbumBtn', self.deleteGalleryAlbum);
		self.registerOnClick('[data-post-vote]', self.forumVoteUp);

		initFunction('#forumPolls', self.initForumPollsJS);
	};

	/**
	 * Handles the user menu click.
	 */
	this.userMenu = function() {
		let userMenuObj = $('#userMenu');
		userMenuObj.not('#userMenuDropdown').click(self.userMenuDropdown);

		return userMenuObj;
	};

	/**
	 * Handles the user menu functionality.
	 */
	this.userMenuDropdown = function() {

		let userMenuDropdown = $('#userMenuDropdown');
		let userMenuContainer = $('#userMenuContainer');

		if(userMenuDropdown.is(':visible')) {
			userMenuContainer.removeClass('active');
		}
		else {

			// Close main menu if shown
			if($('#navbarSupportedContent').hasClass('show') === true) {
				$('#navbarToggler').click();
			}

			userMenuContainer.addClass('active');
		}

		userMenuDropdown.slideToggle(180);
	};

	/**
	 * Hides the use rmenu if the main menu is clicked.
	 */
	this.hideUserMenuOnMainMenuClick = function() {
		if($('#userMenuContainer').hasClass('active') === true) {
			$('#userMenu').click();
		}
	};

	/**
	 * Initiates the CKEditor instances.
	 */
	this.initCKEditors = function() {
		initCKEditor(FORUM_EDITOR_NAME);
		initCKEditor('contentText');
		initCKEditor('commentText');
	};

	/**
	 * Sets the delete post modal text based on the related target value.
	 *
	 * @param event
	 */
	this.deletePostModalSetText = function(event) {

		if($(event.relatedTarget).data('delete-thread') === 1) {
			setModalTitle('deletePostModal', tex('forum.delete_thread'));
			setModalText('deletePostModal', tex('forum.delete_thread.text'));
		}
		else {
			setModalTitle('deletePostModal', tex('forum.delete_post'));
			setModalText('deletePostModal', tex('forum.delete_post.text'));
		}
	};

	/**
	 * Sets the delete gallery image modal text.
	 */
	this.deleteGalleryImageSetText = function() {

		let thumb = '<br><img alt="" src="' + $(this).find('.btn-action').data('thumbnail') + '">';
		let text = tex('gallery.delete_image.text.image');
		text = text.replace(/%image%/i, thumb);

		setModalText('deleteGalleryImageModal', text);
	};

	/**
	 * Delete the notification.
	 */
	this.deleteNotification = function() {

		let obj = $(this);
		let id = obj.data('notification-id');
		let row = $('[data-notification-row="' + id + '"]');
		let actionRow = $('[data-action-row="' + id + '"]');

		actionRow.html('<span><i class="fa fa-spin fa-spinner"></i> ' + tex('global.processing_request') + '</span>').show();
		row.fadeTo(250, 0.33);

		$.ajax({
			method: 'POST',
			url: '/ajax/delete-notification',
			data: { id: id, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {
			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true) {

					let countObj = $('[data-user-notification-count]');
					let count = parseInt(countObj.data('user-notification-count'));
					count = (count - 1);

					if(count > 0) {
						countObj.data('user-notification-count', count);
						countObj.html(count);
					}
					else {
						countObj.remove();
						$('[data-notification-table]').append('<div class="row align-items-center table-row"><div class="col-sm-12 pl-2"><i class="fa fa-info-circle"></i> ' + tex('notifications.no_data') + '</div></div>');
					}

					row.remove();
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deletes all notifications.
	 */
	this.deleteAllNotifications = function() {

		$.ajax({
			method: 'POST',
			url: '/ajax/delete-notifications',
			data: { csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true) {
					if(obj.status === 1) {
						if(_.isEmpty(obj.redirect) === false) {
							location.href = obj.redirect;
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Sets the cookie compliance cookie and fades out the info bar.
	 */
	this.setCookieCompliance = function() {

		let hostname = window.location.hostname;

		if(hostname.substr(0, 4) === 'www.') {
			hostname = hostname.substr(3);
		}

		$('#cookieCompliance').fadeOut();
		Cookies.set('rr_cookie_compliance', '1', { expires: 365, domain: hostname, secure: true });
	};

	/**
	 * Handles the sorting dropdown.
	 */
	this.sortingDropdown = function() {

		let headerObj = $(this);
		let headerIconObj = headerObj.find('i.fa');
		let definer = headerObj.data('sorting-header');
		let contentObj = $('[data-sorting-content="' + definer + '"]');

		contentObj.slideToggle('fast', function() {
			if(contentObj.is(':visible') === true) {
				headerIconObj.removeClass('fa-care-tdown');
				headerIconObj.addClass('fa-caret-up');
			}
			else {
				headerIconObj.removeClass('fa-caret-up');
				headerIconObj.addClass('fa-caret-down');
			}
		});
	};

	/**
	 * Deletes the current users profile image.
	 */
	this.deleteProfileImage = function() {

		setModalText('deleteProfileImageModal', '<i class="fa fa-spin fa-spinner"></i> ' + tex('global.processing_request'));

		$.ajax({
			method: 'POST',
			url: '/ajax/delete-profile-image',
			data: { csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'msg') === true) {
					if(obj.status === 1) {
						$('#removeProfileImageContainer').hide();
						$('#deleteProfileImageModal').modal('hide');
					}
					else {
						setModalErrorText('deleteProfileImageModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
					}
				}
				else {
					setModalErrorText('deleteProfileImageModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
				}
			}
			else {
				setModalErrorText('deleteProfileImageModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			setModalErrorText('deleteProfileImageModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
		});
	};

	/**
	 * Loads the forum post preview HTML.
	 */
	this.postPreview = function() {

		let container = $('#previewContainer');
		let content = CKEDITOR.instances[FORUM_EDITOR_NAME].getData();
		let userID = parseInt($(this).data('post-user-id'));

		$('body').scrollTo('h1');
		container.html('<i class="fa fa-spin fa-spinner"></i> ' + tex('global.loading_preview'));
		container.show();

		$.ajax({
			method: 'POST',
			url: '/ajax/forum/preview-post',
			data: { content: content, user_id: userID, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'data') === true) {
					if(obj.status === 1) {
						container.html(obj.data);
					}
					else {
						container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_STATUS)');
					}
				}
				else {
					container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
				}
			}
			else {
				container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_NO_OBJ)');
			}
		})
		.fail(function() {
			container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_CONN_FAIL)');
		});
	};

	/**
	 * Deletes the given post or thread.
	 */
	this.deletePost = function() {

		setModalText('deletePostModal', '<i class="fa fa-spin fa-spinner"></i> ' + tex('global.processing_request'));

		$.ajax({
			method: 'POST',
			url: '/ajax/forum/delete-post',
			data: { id: $(this).data('post-id'), csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'post_id') === true && _.has(obj, 'redirect') === true) {
					if(obj.status === 1) {

						if(_.isEmpty(obj.redirect) === false) {
							location.href = obj.redirect;
						}
						else {
							$('[data-thread-post-id="' + obj.post_id + '"]').remove();
							$('#deletePostModal').modal('hide');
						}
					}
					else {
						setModalErrorText('deletePostModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
					}
				}
				else {
					setModalErrorText('deletePostModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
				}
			}
			else {
				setModalErrorText('deletePostModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			setModalErrorText('deletePostModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deleted the given gallery image.
	 */
	this.deleteGalleryImage = function() {

		setModalText('deleteGalleryImageModal', '<i class="fa fa-spin fa-spinner"></i> ' + tex('global.processing_request'));

		$.ajax({
			method: 'POST',
			url: '/ajax/galerie/delete-image',
			data: { upload_id: $(this).data('upload-id'), csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'upload_id') === true) {
					if(obj.status === 1) {
						$('[data-upload-file="' + obj.upload_id + '"]').remove();
						$('#deleteGalleryImageModal').modal('hide');
					}
					else {
						setModalErrorText('deleteGalleryImageModal', '<strong>' + tex('global.error') + '</strong> ' + obj.msg);
					}
				}
				else {
					setModalErrorText('deleteGalleryImageModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
				}
			}
			else {
				setModalErrorText('deleteGalleryImageModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			setModalErrorText('deleteGalleryImageModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deleted the given gallery album.
	 */
	this.deleteGalleryAlbum = function() {

		setModalText('deleteGalleryAlbumModal', '<i class="fa fa-spin fa-spinner"></i> ' + tex('global.processing_request'));

		$.ajax({
			method: 'POST',
			url: '/ajax/galerie/delete-album',
			data: { album_id: $(this).data('album-id'), csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true) {
					if(obj.status === 1) {
						if(_.isEmpty(obj.redirect) === false) {
							location.href = obj.redirect;
						}
						else {
							setModalErrorText('deleteGalleryAlbumModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
						}
					}
					else {
						setModalErrorText('deleteGalleryAlbumModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
					}
				}
				else {
					setModalErrorText('deleteGalleryAlbumModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
				}
			}
			else {
				setModalErrorText('deleteGalleryAlbumModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			setModalErrorText('deleteGalleryAlbumModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
		});
	};

	/**
	 * Increases or decreses the post vote count.
	 */
	this.forumVoteUp = function() {

		let obj = $(this);
		let postID = parseInt(obj.data('post-vote'));

		if(ACTIVE_FORUM_VOTE_REQUESTS.includes(postID) === false) {

			ACTIVE_FORUM_VOTE_REQUESTS.push(postID);

			let iconObj = obj.find('.vote-icon');
			let countObj = obj.find('[data-post-vote-value]');

			iconObj.removeClass('fa-thumbs-up').addClass('fa-spin').addClass('fa-spinner');

			$.ajax({
				method: 'POST',
				url: '/ajax/forum/post/vote',
				data: { id: postID, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true && _.has(obj, 'count') === true) {
						if(obj.status === 1) {
							countObj.html(obj.count);
						}
						else {

						}
					}
					else {

					}
				}
				else {

				}
			})
			.fail(function() {

			})
			.always(function() {
				iconObj.removeClass('fa-spin').removeClass('fa-spinner').addClass('fa-thumbs-up');
				ACTIVE_FORUM_VOTE_REQUESTS = _.without(ACTIVE_FORUM_VOTE_REQUESTS, postID);
			});
		}
	};

	/**
	 * Deletes the given appointment.
	 */
	this.deleteAppointment = function() {

		setModalText('deleteAppointmentModal', '<i class="fa fa-spin fa-spinner"></i> ' + tex('global.processing_request'));

		$.ajax({
			method: 'POST',
			url: '/ajax/calendar/delete-appointment',
			data: { id: $(this).data('appointment-id'), csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'redirect') === true) {
					if(obj.status === 1) {
						location.href = obj.redirect;
					}
					else {
						setModalErrorText('deleteAppointmentModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
					}
				}
				else {
					setModalErrorText('deleteAppointmentModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
				}
			}
			else {
				setModalErrorText('deleteAppointmentModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			setModalErrorText('deleteAppointmentModal', '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deletes the given comment.
	 */
	this.deleteComment = function() {

		let commentID = parseInt($(this).data('comment-id'));
		let modalName = 'deleteCommentModal' + commentID;

		setModalText(modalName, '<i class="fa fa-spin fa-spinner"></i> ' + tex('global.processing_request'));

		$.ajax({
			method: 'POST',
			url: '/ajax/comments/delete',
			data: { id: $(this).data('comment-id'), csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true) {
					if(obj.status === 1) {
						location.reload();
					}
					else {
						setModalErrorText(modalName, '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
					}
				}
				else {
					setModalErrorText(modalName, '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
				}
			}
			else {
				setModalErrorText(modalName, '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			setModalErrorText(modalName, '<strong>' + tex('global.error') + '</strong> ' + tex('error.internal_error'));
		});
	};

	/**
	 * Sets or removes a thread subscription.
	 */
	this.threadSubscribe = function() {

		if(self.threadSubscribeFetching === true) {
			return;
		}

		self.threadSubscribeFetching = true;

		let handleObj = $(this);
		let oldIconClass = handleObj.find('i').attr('class');
		let subscribeButtons = $('[data-thread-subscribe]');
		let status = handleObj.data('thread-subscribe');

		subscribeButtons.addClass('disabled');
		subscribeButtons.find('i').attr('class', 'fa fa-spin fa-spinner');

		$.ajax({
			method: 'POST',
			url: '/ajax/forum/thread-subscription',
			data: { id: handleObj.data('thread-id'), status: status, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true) {
					if(obj.status === 1) {

						if(status === 1) {
							handleObj.data('thread-subscribe', 2);
							subscribeButtons.find('i').attr('class', 'fa fa-eye-slash');
							subscribeButtons.find('span').html(tex('global.unsubscribe'));
						}
						else {
							handleObj.data('thread-subscribe', 1);
							subscribeButtons.find('i').attr('class', 'fa fa-eye');
							subscribeButtons.find('span').html(tex('global.subscribe'));
						}
					}
					else {
						alert(tex('global.error') + ' ' + tex('error.internal_error'));
						subscribeButtons.find('i').attr('class', oldIconClass);
					}
				}
				else {
					alert(tex('global.error') + ' ' + tex('error.internal_error'));
					subscribeButtons.find('i').attr('class', oldIconClass);
				}
			}
			else {
				alert(tex('global.error') + ' ' + tex('error.internal_error'));
				subscribeButtons.find('i').attr('class', oldIconClass);
			}
		})
		.fail(function() {
			alert(tex('global.error') + ' ' + tex('error.internal_error'));
			subscribeButtons.find('i').attr('class', oldIconClass);
		})
		.always(function() {
			subscribeButtons.removeClass('disabled');
			self.threadSubscribeFetching = false;
		});
	};

	/**
	 * Loads the TeamSpeak3 viewer contents via AJAX call.
	 */
	this.loadTSViewerData = function() {

		const container = $('#tsViewerContainer');

		$.ajax({
			method: 'POST',
			url: '/ajax/tsviewer',
			data: { csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'data') === true) {
					if(obj.status === 1) {
						container.removeClass('text-center');
						container.html(obj.data);
					}
					else {
						container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_STATUS)');
					}
				}
				else {
					container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
				}
			}
			else {
				container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_NO_OBJ)');
			}
		})
		.fail(function() {
			container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_CONN_FAIL)');
		});
	};

	/**
	 * Resets the users date of birth field.
	 */
	this.resetDateOfBirth = function() {
		$('#userDateOfBirth').val('');
	};

	/**
	 * Toggles the responsive sidebar menu.
	 */
	this.sidebarToggle = function() {
		$(this).toggleClass('show');
		$('#sidebarContent').toggle();
	};

	/**
	 * Loads the image cropper class.
	 */
	this.loadCropper = function() {
		new ImageCropper('registerUserImageCropper');
	};

	/**
	 * Loads the profile JS classes.
	 */
	this.initProfileJS = function() {
		new ProfileTabs();
		new ProfileGames();
	};

	/**
	 * Loads the forum poll class.
	 */
	this.initForumPollsJS = function() {
		new ForumPoll();
	};

	/**
	 * Loads the calendar autocomplete js.
	 */
	this.initCalendarAutocomplete = function() {
		new InputAutocomplete('#appointmentGame', '/ajax/games/search', function(data) {
			$('#appointmentGameID').val(data);
		});
	};

	/**
	 * Loads the lightbox library.
	 */
	this.initLightbox = function() {
		Chocolat(document.querySelectorAll('a.chocolat-image'))
	};

	/**
	 * Toggles the appointment options.
	 */
	this.toggleAppointmentOptions = function(forceVal) {

		let val;

		if(Number.isInteger(forceVal) === true) {
			val = forceVal;
		}
		else {
			val = parseInt($(this).val());
		}

		let freeForAllObj = $('#appointmentFreeForAllOptionsContainer');
		let inviteObj = $('#appointmentInviteOptionsContainer');

		if(val === 1) {
			inviteObj.hide();
			freeForAllObj.show();
		}
		else {
			freeForAllObj.hide();
			inviteObj.show();
		}
	};

	/**
	 * Inits the appointment participants autocomplete.
	 */
	this.initAppointmentAutocomplete = function(appointmentTypeObj) {

		self.toggleAppointmentOptions(parseInt(appointmentTypeObj.val()));

		new InputAutocomplete('#appointmentUserSearch', '/ajax/user/search', function(userID) {

			let inputObj = $('#appointmentUserSearch');
			let users = $('input[name="appointment_participants[]"]');
			let addUserToList = true;

			$.each(users, function(k, v) {
				if(parseInt(v.value) === userID) {
					addUserToList = false;
				}
			});

			if(addUserToList === true) {
				let userName = inputObj.val();

				let participantRowHTML = '<div class="col-12" data-participant-row>';
				participantRowHTML += '  <div class="row g-0">';
				participantRowHTML += '    <div class="col-auto particpant-icon"><a data-remove-participant><i class="fa fa-times"></i></a></div>';
				participantRowHTML += '    <div class="col-auto particpant-icon"><i class="fa fa-circle color-silver"></i></div>';
				participantRowHTML += '    <div class="col-10">' + userName + '</div>';
				participantRowHTML += '  </div>';
				participantRowHTML += '  <input type="hidden" name="appointment_participants[]" value="' + userID + '">';
				participantRowHTML += '</div>';

				let row = $(participantRowHTML);

				$('#appointmentParticipants').append(row);
			}

			inputObj.val('');
		});
	};

	/**
	 * Inits the private message recipient autocomplete.
	 */
	this.initPMAutocomplete = function() {
		new InputAutocomplete('#pmUserSearch', '/ajax/user/search');
	};

	/**
	 * Removes the participant from the list.
	 */
	this.removeParticipant = function() {
		$(this).closest('[data-participant-row]').remove();
	};

	/**
	 * Accept the appointment invitation.
	 */
	this.acceptAppointmentInvite = function() {
		self.appointmentInvite(this, 2);
	};

	/**
	 * Tentative the appointment invitation.
	 */
	this.tentativeAppointmentInvite = function() {
		self.appointmentInvite(this, 4);
	};

	/**
	 * Decline the appointment invitation.
	 */
	this.declineAppointmentInvite = function() {
		self.appointmentInvite(this, 3);
	};

	/**
	 * Copies the the stirng from the data attribute to the clipboard.
	 */
	this.copyToClipboard = function() {

		let obj = $(this);
		let str = obj.data('copy-clipboard');

		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	};

	/**
	 * Select the given input value.
	 */
	this.selectInputValue = function() {
		$(this).select();
	};

	/**
	 * Accepts or declines the appointment invitation.
	 *
	 * @param handle The reference to the calling element.
	 * @param status The invite status to set.
	 */
	this.appointmentInvite = function(handle, status) {

		let appointmentID = $(handle).data('appointment-id');
		let container = $('#appointmentInvite');

		$.ajax({
			method: 'POST',
			url: '/ajax/calendar/invite',
			data: { csrf_token: CSRF_TOKEN, appointment_id: appointmentID, status: status }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true) {
					if(obj.status === 1) {
						location.href = $('[data-current-page-href]').data('current-page-href');
					}
					else {
						container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_STATUS)');
					}
				}
				else {
					container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
				}
			}
			else {
				container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_NO_OBJ)');
			}
		})
		.fail(function() {
			container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_CONN_FAIL)');
		});
	};

	/**
	 * Inits the date pickers.
	 */
	this.initDatePickers = function() {

		$('[data-datetime-picker]').flatpickr({
			locale: 'de',
			enableTime: true,
			time_24hr: true,
			dateFormat: "d.m.Y H:i",
			nextArrow: '<i class="fa fa-caret-right"></i>',
			prevArrow: '<i class="fa fa-caret-left"></i>',
		});

		$('[data-date-picker]').flatpickr({
			locale: 'de',
			enableTime: false,
			dateFormat: "d.m.Y",
			nextArrow: '<i class="fa fa-caret-right"></i>',
			prevArrow: '<i class="fa fa-caret-left"></i>',
		});
	};


	// Call the class constructor
	this.init();
}


$(document).ready(function() {
	CSRF_TOKEN = $('input[name="csrf_token"]').val();
	new Main();
});