/**
 * This class provides functions to upload files by drag and drop.
 *
 * @param {object} selector
 * @param {string} endpointURL The URL of the endpoint.
 * @param {string} templateType The template to use.
 * @constructor
 */
function Upload(selector, endpointURL, templateType) {

	/**
	 * The instance of this class.
	 *
	 * @type {Upload}
	 */
	let self = this;

	/**
	 * The URL of the endpoint.
	 *
	 * @type {string}
	 */
	this.endpointURL = endpointURL;

	/**
	 * The type of the template.
	 *
	 * @type {string}
	 */
	this.templateType = templateType;

	/**
	 * The drag and drop container object.
	 *
	 * @type {?object}
	 */
	this.dragAndDropObj = null;

	/**
	 * The upload list object.
	 *
	 * @type {?object}
	 */
	this.uploadList = null;

	/**
	 * THe type of the data.
	 *
	 * @type {?string}
	 */
	this.dataType = null;

	/**
	 * The ID of the data object.
	 *
	 * @type {?string}
	 */
	this.dataID = null;

	/**
	 * The list of file icons.
	 *
	 * @type {?string}
	 */
	this.fileIcons = null;


	/**
	 * The InputAutocomplete class constructor.
	 */
	this.init = function() {

		self.dragAndDropObj = $(selector);

		if(self.dragAndDropObj.length > 0) {

			self.uploadList = $('#' + self.dragAndDropObj.data('upload-list')).find('[data-upload-row]');
			self.dataType = self.dragAndDropObj.data('type');
			self.dataID = self.dragAndDropObj.data('id');

			this.parseFileIcon();

			self.dragAndDropObj.find('input[type="file"]').on('change', self.getInputFiles);
			self.dragAndDropObj.on('drag dragstart dragend dragover dragenter dragleave drop', self.preventDefaults);
			self.dragAndDropObj.on('dragover dragenter', self.addDNDClass);
			self.dragAndDropObj.on('dragleave dragend drop', self.removeDNDClass);
			self.dragAndDropObj.on('drop', self.getDNDFiles);
		}
	};

	/**
	 * Prevents the default behaviour on drag events.
	 *
	 * @param e The event object.
	 */
	this.preventDefaults = function(e) {
		e.preventDefault();
		e.stopPropagation();
	};

	/**
	 * Adds the drag over class.
	 */
	this.addDNDClass = function() {
		self.dragAndDropObj.addClass('is-dragover');
	};

	/**
	 * Removes the drag over class.
	 */
	this.removeDNDClass = function() {
		self.dragAndDropObj.removeClass('is-dragover');
	};

	/**
	 * Gets the drag and drop files.
	 *
	 * @param e The event object.
	 */
	this.getDNDFiles = function(e) {
		self.handleFiles(e.originalEvent.dataTransfer.files);
	};

	/**
	 * Gets the input field files on select.
	 */
	this.getInputFiles = function() {
		self.handleFiles(this.files);
		this.value = null;
	};

	/**
	 * Handles the uploaded files.
	 *
	 * @param files The FileList object.
	 */
	this.handleFiles = function(files) {

		if(files.length > 0) {
			$.each(files, function(i, file) {

				if(self.isValidMIME(file.type) === true) {

					let viewElement;

					if(self.isImage(file.type) === true) {

						viewElement = self.createViewElement(true, file.type, file.name);
						self.loadPreview(file, viewElement);
					}
					else {
						viewElement = self.createViewElement(false, file.type, file.name);
					}

					self.uploadList.append(viewElement);
					self.uploadFile(file, viewElement);
				}
				else {
					Snackbar.error('[' + file.name + ']<br>' + tex('upload.invalid_file_extension'));
				}
			});
		}
	};

	/**
	 * Gets the file icon list.
	 */
	this.parseFileIcon = function() {

		let fileObj = $('[data-file-icons]');
		if(fileObj.length > 0) {
			self.fileIcons = JSON.parse($(fileObj.get(0)).val());
		}
		else {
			console.warn('[ERROR]: No file types object found [data-file-icons]');
		}
	};

	/**
	 * Get the file icon for non images.
	 *
	 * @param {string} type The MIME type of the file.
	 * @returns {string} The file icon for non images or an empty string.
	 */
	this.getFileIcon = function(type) {

		let icon = '';

		for(let fileType in self.fileIcons) {
			if(type === fileType && self.fileIcons.hasOwnProperty(fileType) === true) {
				icon = self.fileIcons[fileType];
				break;
			}
		}

		return icon;
	};

	/**
	 * Checks if the given MIME type is a valid type.
	 *
	 * @param {string} type The file MIME type.
	 * @returns {boolean} Returns True if the MIME type is valid otherwise False.
	 */
	this.isValidMIME = function(type) {

		let status = false;
		let validList = self.fileIcons;

		// Add the images MIME types
		validList['image/jpg'] = '';
		validList['image/jpeg'] = '';
		validList['image/png'] = '';
		validList['image/gif'] = '';
		validList['image/webp'] = '';

		for(let mime in validList) {
			if(type === mime) {
				status = true;
				break;
			}
		}

		return status;
	};

	/**
	 * Gets the is image status for the given MIME type.
	 *
	 * @param {string} type The MIME type of the file.
	 * @returns {boolean} Returns True if the file is an image otherwise False.
	 */
	this.isImage = function(type) {

		let imageTypes = [
			'image/jpg',
			'image/jpeg',
			'image/png',
			'image/gif',
			'image/webp',
		];

		return _.contains(imageTypes, type);
	};

	/**
	 * Creates a upload view element.
	 *
	 * @param {boolean} isImage If True the view element is rendered as image.
	 * @param {string} type The MIME type of the file.
	 * @param {string} fileName The name of the file.
	 * @returns {jQuery.fn.init|jQuery|HTMLElement}
	 */
	this.createViewElement = function(isImage, type, fileName) {

		let html = '<div class="col-3 text-center" data-upload-file="">';

		if(isImage === true) {
			html += '	<a class="upload-img-link" target="_blank" href="#"><img class="upload-img" src=""></a>';

			if(this.templateType === 'gallery-image') {
				html += '<div class="upload-delete text-center mt-2">';
				html += '  <a data-upload-id="" data-thumbnail="" data-toggle="modal" data-target="#deleteGalleryImageModal"><i class="fa fa-trash-alt"></i></a>';
				html += '</div>';
			}
		}
		else {
			html += '	<i class="file-icon ' + self.getFileIcon(type) + '"></i>';
		}

		if(this.templateType === 'copy-link') {
			html += '	<span class="file-name">' + fileName + '</span>';
		}

		if(this.templateType === 'copy-link') {
			html += '	<div class="link-field">';
			html += '	  <div class="row align-items-center">';
			html += '	    <div class="col-11"><input href="javascript:" data-link data-select-value value=""></div>';
			html += '	    <div class="col-1 text-center"><i class="copy-link fa fa-copy" title="' + tex('global.copy_to_clipboard') + '" data-copy-clipboard=""></i></div>';
			html += '	  </div>';
			html += ' </div>';
		}

		html += '	<div class="progress">';
		html += '		<div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">';
		html += '			<label>0%</label>';
		html += '	  </div>';
		html += '	</div>';

		html += '<div class="error">';
		html += '</div>';


		html += '</div>';

		return $(html);
	};

	/**
	 * Loads the preview image.
	 *
	 * @param file The File object.
	 * @param object The view element.
	 */
	this.loadPreview = function(file, object) {

		let reader = new FileReader();
		reader.onload = function(e) {
			object.find('.upload-img').attr('src', e.target.result);
		};
		reader.readAsDataURL(file);
	};

	/**
	 * Uploads the given file and shows the progress.
	 *
	 * @param file The File object.
	 * @param object The view element.
	 */
	this.uploadFile = function(file, object) {

		let client = new XMLHttpRequest();
		let formData = new FormData();
		let errorObj = object.find('.error');
		let uploadPercent = object.find('label');
		let uploadBarOuter = object.find('.progress');
		let uploadBar = object.find('.progress-bar');
		let linkField = object.find('.link-field');
		let uploadImg = object.find('.upload-img');
		let uploadImgLink = object.find('.upload-img-link');
		let uploadDelete = object.find('.upload-delete');

		client.onerror = function() {
			uploadBar.css('width', '100%');
			uploadPercent.html('Fehler beim Upload');
		};

		client.onload = function(e) {
			uploadBar.css('width', '100%');
			uploadPercent.html('100%');
		};

		client.upload.onprogress = function(e) {
			let p = Math.round(100 / e.total * e.loaded);
			uploadBar.css('width', p + '%');
			uploadPercent.html(p + '%');
		};

		client.onreadystatechange = function() {
			if(this.readyState === 4 && this.status === 200) {

				uploadBar.removeClass('bg-warning').addClass('bg-success');
				uploadBarOuter.fadeOut('slow', function() {
					uploadBarOuter.remove();
				});

				let json = JSON.parse(client.responseText);

				if(_.isObject(json) === true) {
					if(_.has(json, 'status') === true) {
						if(json.status === 1) {

							object.attr('data-upload-file', json.data.id);

							linkField.find('[data-link]').val(json.data.link);
							linkField.find('[data-copy-clipboard]').data('copy-clipboard', json.data.link);
							linkField.show();

							uploadImgLink.attr('href', json.data.link);

							uploadImg.attr('src', json.data.thumbnail);

							uploadDelete.find('[data-upload-id]').data('upload-id', json.data.id);
							uploadDelete.find('[data-thumbnail]').data('thumbnail', json.data.thumbnail);
							uploadDelete.show();
						}
						else {
							self.showUploadError(errorObj, uploadImgLink, json.data);
						}
					}
					else {
						self.showUploadError(errorObj, uploadImgLink, json.data);
					}
				}
				else {
					self.showUploadError(errorObj, uploadImgLink, json.data);
				}
			}
		};

		client.onabort = function() {
			uploadBar.css('width', '100%');
			uploadPercent.html('Upload abgebrochen');
		};

		formData.append('csrf_token', CSRF_TOKEN);
		formData.append('id', self.dataID);
		formData.append('type', self.dataType);
		formData.append('file', file);

		client.open('POST', self.endpointURL);
		client.send(formData);
	};

	this.showUploadError = function(errorObj, uploadImgLink, errorText) {
		uploadImgLink.attr('href', null);
		errorObj.html('<i class="fa fa-times"></i> ' + errorText);
		errorObj.show();
	};


	this.init();
}
