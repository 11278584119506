/**
 * Replaces default inputs of type checkbox with a custom layout.
 */
$.fn.dcCheckbox = function() {

	/**
	 * @type {string} The ID prefix for generated IDs.
	 */
	const ID_PREFIX = 'dcCheckbox';

	/**
	 * @type {string} The template string for the custom checkbox view.
	 */
	const TEMPLATE = '<span id="%s" class="checkbox">%s</span>';

	/**
	 * @type {string} The template for the checked state.
	 */
	const TEMPLATE_CHECKED = '<i class="fa fa-check"></i>';


	/**
	 * @type {$} The reference of this class.
	 */
	let self = this;

	/**
	 * @type {number} The number of processed checkboxes.
	 */
	let numberOfCheckboxes = 1;


	/**
	 * Sets an generated ID for the checkbox if no ID is set.
	 *
	 * @param obj The source checkbox object.
	 */
	this.setID = function(obj) {
		if(obj.attr('id') === undefined || obj.attr('id') === '') {
			obj.attr('id', ID_PREFIX + numberOfCheckboxes);
		}
	};

	/**
	 * Registers the required events for the custom checkbox view.
	 *
	 * @param obj The source checkbox object.
	 */
	this.registerEventHandlers = function(obj) {

		$(document).on('click', '#' + obj.attr('id') + 'View', self.clickHandler);
		$(document).on('click', 'label[for="' + obj.attr('id') + '"]', self.labelClickHandler);
	};

	/**
	 * Appends the custom view HTML code after the source checkbox.
	 *
	 * @param obj The source checkbox object.
	 */
	this.createView = function(obj) {

		let viewID = obj.attr('id') + 'View';
		let viewChecked = (obj.is(':checked') === true) ? TEMPLATE_CHECKED : '';

		obj.after(TEMPLATE.format(viewID, viewChecked));

		$.each(obj.data(), function(name, value) {
			$('#' + viewID).attr('data-' + name, value);
		});
	};

	/**
	 * Prevents the label from selecting the source checkbox.
	 */
	this.labelClickHandler = function(e) {

		e.preventDefault();
		$('#' + $(this).attr('for') + 'View').click();
	};

	/**
	 * The click handler for the radio button.
	 */
	this.clickHandler = function() {

		let obj = $(this);
		let srcID = obj.attr('id').slice(0, -4);
		let srcObj = $('#' + srcID);

		if(document.getElementById(srcID).checked === true) {
			self.setInactive(obj, srcObj);
		}
		else {
			self.setActive(obj, srcObj);
		}
	};

	/**
	 * Sets the checkbox active.
	 *
	 * @param obj The template checkbox object.
	 * @param srcObj The source checkbox input.
	 */
	this.setActive = function(obj, srcObj) {
		obj.append(TEMPLATE_CHECKED);
		srcObj.prop('checked', true);
	};

	/**
	 * Sets the checkbox inactive.
	 *
	 * @param obj The template checkbox object.
	 * @param srcObj The source checkbox input.
	 */
	this.setInactive = function(obj, srcObj) {
		obj.children().remove();
		srcObj.prop('checked', false);
	};


	// Process all passed checkboxes
	return this.each(function() {

		let obj = $(this);

		if(obj.data('checkbox-proccessed') !== '1') {

			self.setID(obj);
			self.createView(obj);
			self.registerEventHandlers(obj);

			obj.hide();
			obj.data('checkbox-proccessed', 1);

			numberOfCheckboxes++;
		}
	});
};