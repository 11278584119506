/**
 * This class provides functions to show a autocomplete for an input text field.
 *
 * @param {object} selector
 * @param {string} endpointURL The URL of the endpoint.
 * @param {?function} clickCallback A callback function when selectiong an list item.
 * @constructor
 */
function InputAutocomplete(selector, endpointURL, clickCallback) {

	/**
	 * The number of miliseconds to wait before fetching the autocomplete results
	 * from the endpoint.
	 *
	 * @type {number}
	 */
	const ENDPOINT_FETCH_DELAY = 800;

	/**
	 * The CSS class name of autocomplete items.
	 *
	 * @type {string}
	 */
	const AUTOCOMPLETE_CLASS_NAME = 'autocomplete-items';

	/**
	 * The CSS class name of active autocomplete items.
	 *
	 * @type {string}
	 */
	const AUTOCOMPLETE_ACTIVE_CLASS_NAME = 'autocomplete-active';

	/**
	 * The CSS class name of the autocomplete list.
	 *
	 * @type {string}
	 */
	const AUTOCOMPLETE_LIST_CLASS_NAME = 'autocomplete-list';


	/**
	 * The instance of this class.
	 *
	 * @type {InputAutocomplete}
	 */
	let self = this;

	/**
	 * The URL of the endpoint.
	 *
	 * @type {string}
	 */
	this.endpointURL = endpointURL;

	/**
	 * The input jQuery object.
	 *
	 * @type {?object}
	 */
	this.inputObj = null;

	/**
	 * The current autocomplete index.
	 *
	 * @type {number}
	 */
	this.currentFocus = -1;

	/**
	 * The timeout ID.
	 *
	 * @type {?number}
	 */
	this.inputTimeout = null;

	/**
	 * The click callback function.
	 *
	 * @type {?function}
	 */
	this.clickCallback = clickCallback;


	/**
	 * The InputAutocomplete class constructor.
	 */
	this.init = function() {

		self.inputObj = $(selector);

		if(_.isObject(self.inputObj) === true) {

			self.inputObj.parent().addClass('autocomplete');

			self.inputObj.on('input', self.onInput);
			self.inputObj.on('keydown', self.onKeyDown);

			// Close all dropdown when clicking outside the element
			$(document).on('click', function(e) {
				self.closeAllLists($(e.target));
			});
		}
	};

	/**
	 * Execute a function when someone writes in the input field.
	 */
	this.onInput = function() {

		if(self.inputTimeout !== null) {
			window.clearTimeout(self.inputTimeout);
			self.inputTimeout = null;
		}

		let handle = this;
		let value = handle.value;

		if(_.isEmpty(value) === false) {

			self.currentFocus = -1;

			self.inputTimeout = window.setTimeout(function() {
				self.fetchResults(handle, value);
			}, ENDPOINT_FETCH_DELAY);
		}
	};

	/**
	 * Move the active item up or down.
	 */
	this.onKeyDown = function(e) {

		let handle = this;
		let obj = document.getElementById(handle.id + AUTOCOMPLETE_LIST_CLASS_NAME);

		if (_.isObject(obj) === true) {

			let items = obj.getElementsByTagName('div');

			if (e.keyCode === 40) {
				self.currentFocus++;
				self.addActive(items);
			}
			else if (e.keyCode === 38) {
				self.currentFocus--;
				self.addActive(items);
			}
			else if (e.keyCode === 13) {

				e.preventDefault();

				if (self.currentFocus > -1) {
					items[self.currentFocus].click();
				}
			}
		}
	};

	/**
	 * Fetch the results from the endpoint.
	 *
	 * @param {object} handle The input field handle.
	 * @param {string} value The value from the input field.
	 */
	this.fetchResults = function(handle, value) {

		$.ajax({
			method: 'POST',
			url: self.endpointURL,
			data: { query: value, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {
			if (_.isObject(obj) === true) {
				if (_.has(obj, 'results') === true) {
					self.createAutocomplete(handle, obj.results, value);
				}
			}
		});
	};

	/**
	 * Creates the HTML for the autocomplete list.
	 *
	 * @param {object} handle The handle of the input field.
	 * @param {object} data The data from the endpoint.
	 * @param {string} value The input value for the search.
	 */
	this.createAutocomplete = function(handle, data, value) {

		self.closeAllLists();

		let root = document.createElement('div');
		root.setAttribute('id', handle.id + 'autocomplete-list');
		root.setAttribute('class', AUTOCOMPLETE_CLASS_NAME);

		handle.parentNode.appendChild(root);

		let item;

		for(let key in data) {

			if(_.has(data, key) === true) {

				let result = data[key];

				item = document.createElement('div');

				// Make the matching part bold
				item.innerHTML = "<strong>" + result.text.substr(0, value.length) + "</strong>";
				item.innerHTML += result.text.substr(value.length);

				item.innerHTML += "<input type='hidden' value='" + result.text + "'>";

				item.addEventListener('click', function() {
					self.inputObj.val(this.getElementsByTagName('input')[0].value);
					self.closeAllLists();

					if(_.isFunction(self.clickCallback) === true) {
						let callbackFunction = self.clickCallback;
						callbackFunction(result.data, result.text);
					}
				});

				root.appendChild(item);
			}
		}
	};

	/**
	 * Close all autocomplete lists in the document, except the one passed as an
	 * argument.
	 *
	 * @param element A element that should not be closed.
	 */
	this.closeAllLists = function(element) {

		let x = document.getElementsByClassName(AUTOCOMPLETE_CLASS_NAME);
		for (let i = 0; i < x.length; i++) {
			if (element !== x[i] && element !== self.inputObj) {
				x[i].parentNode.removeChild(x[i]);
			}
		}
	};

	/**
	 * Removes the active class from all items expect the given item.
	 *
	 * @param {NodeListOf<Element>} items The list of dropdown items.
	 */
	this.removeActive = function(items) {
		for (let i = 0; i < items.length; i++) {
			items[i].classList.remove(AUTOCOMPLETE_ACTIVE_CLASS_NAME);
		}
	};

	/**
	 * Adds the active class to the given item.
	 *
	 * @param {NodeListOf<Element>} items The list of dropdown items.
	 */
	this.addActive = function(items) {

		self.removeActive(items);

		if(self.currentFocus < 0 || self.currentFocus >= items.length) {
			self.currentFocus = 0;
		}

		items[self.currentFocus].classList.add(AUTOCOMPLETE_ACTIVE_CLASS_NAME);
	};


	this.init();
}
