/**
 * This class provides the functions to init an shoutbox.
 */
function GameServerManager() {

	/**
	 * @type {number} The number of miliseconds until the next refresh.
	 */
	const REFRESH_INTERVAL = 3000;


	/**
	 * The instance of this class.
	 *
	 * @type {GameServerManager}
	 */
	let self = this;


	/**
	 * Initializes the class.
	 */
	this.init = () => {

		self.fetch();

		$(document).on('click', '[data-game-server-manager-action]', function(e) { self.actionClick(e, this); });

		window.setInterval(self.fetch, REFRESH_INTERVAL);
	};

	/**
	 * Fetches the current server status.
	 */
	this.fetch = () => {

		$('[data-game-server-manager]').each((k, v) => {

			const obj = $(v);
			const containerName = obj.data('game-server-manager-docker-container-name');

			$.ajax({
				method: 'POST',
				url: '/ajax/game-server-manager/get-status/',
				data: { csrf_token: CSRF_TOKEN, container_name: containerName },
				async: true,
				dataType: 'json',
				success: function (json) {

					if(json.data.starting === true) {
						self.setActionStatusStarting(obj);
					}
					else if(json.data.stopping === true) {
						self.setActionStatusStopping(obj);
					}
					else if(json.data.running === true) {
						self.setActionStatusRunning(obj, json.data);
					}
					else {
						self.setActionStatusStop(obj);
					}
				}
			});
		});
	};

	/**
	 * The callback function for the action buttons.
	 */
	this.actionClick = (e, elem) => {

		e.preventDefault();

		const obj = $(elem);
		const action = obj.data('game-server-manager-action');
		const parentObj = obj.parent().parent();

		if(action !== 'start' && action !== 'stop' && action !== 'restart') {
			return;
		}

		if(action === 'start') {
			self.setActionStatusStarting(parentObj);
			Snackbar.success('<i class="fa fa-check-circle"></i> ' + tex('gameserver_manager.action.start_queued'));
		}
		else if(action === 'restart') {
			self.setActionStatusRestarting(parentObj);
			Snackbar.success('<i class="fa fa-check-circle"></i> ' + tex('gameserver_manager.action.restart_queued'));
		}
		else {
			self.setActionStatusStopping(parentObj);
			Snackbar.success('<i class="fa fa-check-circle"></i> ' + tex('gameserver_manager.action.stop_queued'));
		}

		$.ajax({
			method: 'POST',
			url: '/ajax/game-server-manager/set-action/',
			data: { csrf_token: CSRF_TOKEN, container_name: obj.data('game-server-manager-docker-container-name'), action: action },
			async: true,
			dataType: 'json',
		});
	};

	/**
	 * Sets the starting status for a server.
	 *
	 * @param {Object} obj The jQuery element.
	 */
	this.setActionStatusStarting = (obj) => {

		obj.find('[data-game-server-manager-status-starting]').removeClass('d-none');
		obj.find('[data-game-server-manager-status-stopping]').addClass('d-none');
		obj.find('[data-game-server-manager-status-started]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopped]').addClass('d-none');
		obj.find('[data-game-server-manager-status-loading]').addClass('d-none');

		obj.find('[data-game-server-manager-action="loading"]').removeClass('d-none');
		obj.find('[data-game-server-manager-action="start"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="restart"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="stop"]').addClass('d-none');
	};

	/**
	 * Sets the restarting status for a server.
	 *
	 * @param {Object} obj The jQuery element.
	 */
	this.setActionStatusRestarting = (obj) => {

		obj.find('[data-game-server-manager-status-restarting]').removeClass('d-none');
		obj.find('[data-game-server-manager-status-starting]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopping]').addClass('d-none');
		obj.find('[data-game-server-manager-status-started]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopped]').addClass('d-none');
		obj.find('[data-game-server-manager-status-loading]').addClass('d-none');

		obj.find('[data-game-server-manager-action="loading"]').removeClass('d-none');
		obj.find('[data-game-server-manager-action="start"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="restart"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="stop"]').addClass('d-none');
	};

	/**
	 * Sets the stopping status for a server.
	 *
	 * @param {Object} obj The jQuery element.
	 */
	this.setActionStatusStopping = (obj) => {

		obj.find('[data-game-server-manager-status-stopping]').removeClass('d-none');
		obj.find('[data-game-server-manager-status-restarting]').addClass('d-none');
		obj.find('[data-game-server-manager-status-starting]').addClass('d-none');
		obj.find('[data-game-server-manager-status-started]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopped]').addClass('d-none');
		obj.find('[data-game-server-manager-status-loading]').addClass('d-none');

		obj.find('[data-game-server-manager-action="loading"]').removeClass('d-none');
		obj.find('[data-game-server-manager-action="start"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="restart"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="stop"]').addClass('d-none');
	};

	/**
	 * Sets the running status for a server.
	 *
	 * @param {Object} obj The jQuery element.
	 * @param {Object} resp The response data object.
	 */
	this.setActionStatusRunning = (obj, resp) => {

		if(resp.players_online !== undefined && resp.players_online !== false) {
			obj.find('[data-game-server-players-online]').html(`${resp.players_online.online} / ${resp.players_online.max}`);
		}
		else {
			obj.find('[data-game-server-players-online]').html('---');
		}

		if(resp.auto_stop_disabled === true) {
			obj.find('[data-game-server-manager-flag-disabled-auto-stop]').removeClass('d-none');
		}
		else {
			obj.find('[data-game-server-manager-flag-disabled-auto-stop]').addClass('d-none');
		}

		obj.find('[data-game-server-started-seconds]').html(resp.seconds_since_start);

		obj.find('[data-game-server-manager-status-started]').removeClass('d-none');
		obj.find('[data-game-server-manager-status-restarting]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopped]').addClass('d-none');
		obj.find('[data-game-server-manager-status-loading]').addClass('d-none');
		obj.find('[data-game-server-manager-status-starting]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopping]').addClass('d-none');

		obj.find('[data-game-server-manager-action="loading"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="start"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="restart"]').removeClass('d-none');
		obj.find('[data-game-server-manager-action="stop"]').removeClass('d-none');
	};

	/**
	 * Sets the stopped status for a server.
	 *
	 * @param {Object} obj The jQuery element.
	 */
	this.setActionStatusStop = (obj) => {

		obj.find('[data-game-server-manager-status-started]').addClass('d-none');
		obj.find('[data-game-server-manager-status-restarting]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopped]').removeClass('d-none');
		obj.find('[data-game-server-manager-status-loading]').addClass('d-none');
		obj.find('[data-game-server-manager-status-starting]').addClass('d-none');
		obj.find('[data-game-server-manager-status-stopping]').addClass('d-none');

		obj.find('[data-game-server-manager-action="loading"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="restart"]').addClass('d-none');
		obj.find('[data-game-server-manager-action="start"]').removeClass('d-none');
		obj.find('[data-game-server-manager-action="stop"]').addClass('d-none');
	};


	this.init();
}
