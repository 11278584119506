/**
 * Replaces default inputs of type radio with a custom layout.
 */
$.fn.dcRadioButton = function() {

	/**
	 * @type {string} The ID prefix for generated IDs.
	 */
	const ID_PREFIX = 'dcRadioButton';

	/**
	 * @type {string} The template string for the custom radio button view.
	 */
	const TEMPLATE = '<span id="%s" class="radio">%s</span>';

	/**
	 * @type {$} The reference of this class.
	 */
	let self = this;

	/**
	 * @type {number} The number of processed radio buttons.
	 */
	let numberOfRadios = 1;


	/**
	 * Sets an generated ID for the radio button if no ID is set.
	 *
	 * @param obj The source radio button object.
	 */
	this.setID = function(obj) {
		if(obj.attr('id') === undefined || obj.attr('id') === '') {
			obj.attr('id', ID_PREFIX + numberOfRadios);
		}
	};

	/**
	 * Registers the required events for the custom radio button view.
	 *
	 * @param obj The source radio button object.
	 */
	this.registerEventHandlers = function(obj) {

		// Register click event for view
		$('#' + obj.attr('id') + 'View').click(self.clickHandler);

		// Handle direct clicks via labels to the original input element
		obj.change(function() {
			$('#' + $(this).attr('id') + 'View').click();
		});
	};

	/**
	 * Appends the custom view HTML code after the source radio button.
	 *
	 * @param obj The source radio button object.
	 */
	this.createView = function(obj) {
		let viewID = obj.attr('id') + 'View';
		let viewChecked = (obj.is(':checked') === true) ? '<span></span>' : '';

		obj.after(TEMPLATE.format(viewID, viewChecked));

		$.each(obj.data(), function(name, value) {
			$('#' + viewID).attr('data-' + name, value);
		});
	};

	/**
	 * The click handler for the radio button.
	 */
	this.clickHandler = function() {

		let srcID = $(this).attr('id').slice(0, -4);
		let srcObj = $('#' + srcID);
		let radioGroupName = srcObj.attr('name');

		// Deactivate all radio buttons of the same group
		$('input[name="' + radioGroupName + '"]').each(function(key, value) {
			$('#' + $(value).attr('id') + 'View').children().remove();
			$(value).prop('checked', false);
		});

		$(this).append('<span></span>');
		srcObj.click();
		srcObj.prop('checked', true);
	};


	// Process all passed radio button
	return this.each(function() {

		let obj = $(this);

		if(obj.data('radio-proccessed') !== '1' && obj.attr('name') !== 'form_framework_js_id') {

			self.setID(obj);
			self.createView(obj);
			self.registerEventHandlers(obj);

			obj.hide();
			obj.data('radio-proccessed', 1);

			numberOfRadios++;
		}
	});
};