/**
 * This class provides functions to control forum polls.
 *
 * @constructor
 */
function ForumPoll() {

	/**
	 * The ID of the answer template element.
	 *
	 * @type {string}
	 */
	const ANSWER_TEMPLATE_ID = 'forumPollAnswerTemplate';

	/**
	 * The ID of the answer block.
	 *
	 * @type {string}
	 */
	const ANSWER_CONTAINER_ID = 'forumPollAnswers';

	/**
	 * The poll vote row selector.
	 *
	 * @type {string}
	 */
	const POLL_VOTE_DATA_SELECTOR = 'data-poll-vote';

	/**
	 * The poll result row selector.
	 *
	 * @type {string}
	 */
	const POLL_RESULT_DATA_SELECTOR = 'data-poll-result';


	/**
	 * The instance of this class.
	 *
	 * @type {ForumPoll}
	 */
	let self = this;

	/**
	 * The answer template object for cloning.
	 *
	 * @type {object}
	 */
	this.answerTemplate = null;

	/**
	 * The answer container object for clones nodes.
	 *
	 * @type {object}
	 */
	this.answerContainer = null;


	/**
	 * The ForumPoll class constructor.
	 */
	this.init = function() {

		$(document).on('click', '#addForumPollAnswer', self.addAnswer);
		$(document).on('click', '[data-poll-delete-answer]', self.deleteAnswer);
		$(document).on('click', '#forumPollResult', self.showResults);
		$(document).on('click', '#forumPollVote', self.vote);
		self.answerTemplate = $('#' + ANSWER_TEMPLATE_ID);
		self.answerContainer = $('#' + ANSWER_CONTAINER_ID);
	};

	/**
	 * Adds a new answer input field.
	 */
	this.addAnswer = function() {

		let nextIndex = self.getNextIndex();
		let count = $('[data-poll-answer]').length;
		let template = self.answerTemplate.clone();
		let id = 'threadPollAnswer' + nextIndex;

		template.find('[data-poll-answer]').attr('data-poll-answer', nextIndex);
		template.find('[data-answer-input]').attr('id', id);
		template.find('[data-answer-input]').attr('name', 'thread_poll_answer[' + nextIndex + ']');
		template.find('[data-answer-label]').html(count + '. ' + tex('global.answer')).attr('for', 'threadPollAnswer' + nextIndex);
		template.find('[data-poll-delete-answer]').attr('data-poll-delete-answer', nextIndex);

		self.answerContainer.append(template.html());

		$('#' + id).focus();
	};

	/**
	 * Deletes the poll answer.
	 */
	this.deleteAnswer = function() {

		let index = $(this).data('poll-delete-answer');
		$('[data-poll-answer="' + index + '"]').remove();
	};

	/**
	 * Gets the next poll answer ID.
	 *
	 * @returns {number}
	 */
	this.getNextIndex = function() {

		let index = 0;
		let objects = $('[data-poll-answer]');

		if(objects.length > 0) {
			objects.each(function(k, v) {
				let entryIndex = parseInt($(v).attr('data-poll-answer'));
				if(index < entryIndex) {
					index = entryIndex;
				}
			});
		}

		index++;

		return index;
	};

	/**
	 * Toggles the result display.
	 */
	this.showResults = function() {
		let resultBtnObj = $('#forumPollResult');
		let voteBtnObj = $('#forumPollVote');
		let resultObj = $('[' + POLL_RESULT_DATA_SELECTOR + ']');

		$('[' + POLL_VOTE_DATA_SELECTOR + ']').toggleClass('d-none').toggleClass('d-flex');
		resultObj.toggleClass('d-none');

		if(resultObj.hasClass('d-none') === true) {
			resultBtnObj.html('<i class="fa fa-chart-line"></i> ' + tex('poll.show_results'));
			voteBtnObj.show();
		}
		else {
			resultBtnObj.html('<i class="fa fa-vote-yea"></i> ' + tex('poll.show_voting'));
			voteBtnObj.hide();
		}
	};

	/**
	 * Sends the voting for the current user.
	 */
	this.vote = function() {

		const container = $('#forumPollButtons');
		let answers = [];
		let threadID = parseInt($('[data-thread-id]').data('thread-id'));
		$('[name="poll_answer"]:checked').each(function(k, v) {
			answers.push($(v).val());
		});

		container.html('<i class="fa fa-spin fa-spinner"></i> ' + tex('global.send'));

		$.ajax({
			method: 'POST',
			url: '/ajax/forum/poll/vote',
			data: { csrf_token: CSRF_TOKEN, thread_id: threadID, answers: answers }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'data') === true) {
					if(obj.status === 1) {

						$('#forumPollContainer').html(obj.data);
						container.remove();
					}
					else {
						container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_STATUS)');
					}
				}
				else {
					container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
				}
			}
			else {
				container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_NO_OBJ)');
			}
		})
		.fail(function() {
			container.html('<i class="fa fa-times"></i><br>' + tex('error.internal_error') + ' (ERR_CONN_FAIL)');
		});
	};


	this.init();
}