/**
 * This class provides functions to control the login process.
 *
 * @constructor
 */
function Login() {

	/**
	 * The instance of this class.
	 *
	 * @type {Login}
	 */
	let self = this;

	/**
	 * The error stack holding the error messages.
	 */
	this.errorStack = [];

	/**
	 * The loading indicator object.
	 */
	this.loadingIndicator = null;

	/**
	 * The login button object.
	 */
	this.loginButton = null;


	/**
	 * The Login class constructor.
	 */
	this.init = function() {
		$('#loginForm').on('submit', self.onSubmit);
		self.loadingIndicator = $('#loginLoading');
		self.loginButton = $('#loginBtn');
	};

	/**
	 * Is called when the login form has been submitted.
	 *
	 * @returns {boolean} Return True to do a normal form request or False to
	 *     prevent the form request.
	 */
	this.onSubmit = function() {

		self.resetErrorStatus();

		self.loginButton.prop('disabled', true);
		self.loadingIndicator.show();

		self.checkFieldIsEmpty('#login_user_name');
		self.checkFieldIsEmpty('#login_user_password');

		if(self.errorStack.length === 0) {
			self.sendLoginRequest($('#login_user_name').val(), $('#login_user_password').val());
		}
		else {
			self.showErrors();
		}

		return false;
	};

	/**
	 * Checks if the given field is empty and adds a error message to the error stack.
	 *
	 * @param {string} fieldSelector The field selector.
	 */
	this.checkFieldIsEmpty = function(fieldSelector) {
		if($(fieldSelector).val() === '') {
			self.errorStack.push({ field: fieldSelector, errorCode: 'error.empty_field_value' });
		}
	};

	/**
	 * Sends the users login data to the server and shows the status to the user.
	 *
	 * @param {string} username The username.
	 * @param {string} password The user password.
	 */
	this.sendLoginRequest = function(username, password) {

		$.ajax({
			method: 'POST',
			url: '/ajax/login',
			data: { username: username, password: password, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'msg') === true) {

					if(obj.status === 1) {
						location.href = '/' + $('*[data-login-redirect]').data('login-redirect') + '/';
					}
					else if(obj.status === 2) {
						self.errorStack.push({ field: null, errorCode: null, errorMessage: obj.msg });
						self.showErrors();
					}
					else {
						self.errorStack.push({ field: null, errorCode: 'error.login.ajax.invalid_status' });
						self.showErrors();
					}
				}
				else {
					self.errorStack.push({ field: null, errorCode: 'error.login.ajax.props_missing' });
					self.showErrors();
				}
			}
			else {
				self.errorStack.push({ field: null, errorCode: 'error.login.ajax.not_an_object' });
				self.showErrors();
			}
		})
		.fail(function() {
			self.errorStack.push({ field: null, errorCode: 'error.internal_ajax_error' });
			self.showErrors();
		});
	};

	/**
	 * Shows the errors from the error stack.
	 */
	this.showErrors = function() {

		let html = '';
		let errorBox = $('#loginErrors');

		if(self.errorStack.length > 0) {

			html += '<div class="alert alert-danger" role="alert">';

			for(let key in self.errorStack) {
				if(_.has(self.errorStack, key) === true) {

					let obj = self.errorStack[key];
					let fieldLabel = self.getErrorFieldLabel(obj);
					let errorText = self.getErrorMessage(obj);

					html += '<div>' + fieldLabel + errorText + '</div>';
				}
			}

			html += '</div>';
		}

		self.loginButton.prop('disabled', false);
		errorBox.html(html);

		if(html !== '') {
			self.loadingIndicator.hide();
		}
	};

	/**
	 * Gets the field label retrived by the given field selector.
	 *
	 * @param {object} obj The error object.
	 * @returns {string} The field label retrived by the given field selector.
	 */
	this.getErrorFieldLabel = function(obj) {

		let fieldLabel = '';
		if(obj.field !== null) {
			let fieldID = obj.field.slice(1, obj.field.length);
			fieldLabel = $('label[for="' + fieldID + '"]').html();

			if(fieldLabel !== '') {
				fieldLabel = '<strong>' + fieldLabel + ':</strong> ';
			}
		}

		return fieldLabel;
	};

	/**
	 * Gets the error message from the given error object.
	 *
	 * @param {object} obj The error object.
	 * @returns {string} Returns the error message as string.
	 */
	this.getErrorMessage = function(obj) {

		let errorText = '';

		if(_.isObject(obj) === true) {
			if(_.has(obj, 'errorCode') === true && _.isEmpty(obj.errorCode) === false) {
				errorText = tex(obj.errorCode);
			}
			else if(_.has(obj, 'errorMessage') === true && _.isEmpty(obj.errorMessage) === false) {
				errorText = obj.errorMessage;
			}
		}

		if(_.isEmpty(errorText) === true) {
			errorText = 'Internal error occured!';
		}

		return errorText;
	};

	/**
	 * Resets the error stack and removes the currently displayed error message.
	 */
	this.resetErrorStatus = function() {
		self.errorStack = [];
		$('#loginErrors').html('');
	};


	this.init();
}