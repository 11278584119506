/**
 * This class provides methods to control the profile tabs.
 *
 * @constructor
 */
function ProfileTabs() {

	/**
	 * The instance of this class.
	 *
	 * @type {ProfileTabs}
	 */
	let self = this;

	/**
	 * The name of the user.
	 *
	 * @type {?string}
	 */
	this.userName = null;

	/**
	 * The jqXHR object form the current request.
	 *
	 * @type {?object}
	 */
	this.jqXHR = null;

	/**
	 * The status if an AJAX request is fetching data.
	 *
	 * @type {boolean}
	 */
	this.fetchingData = false;


	/**
	 * The ProfileTabs class constructor.
	 */
	this.init = function() {

		self.userName = $('[data-user]').data('user');
		self.fetch('about-me');

		$('#profileTab a').on('shown.bs.tab', self.loadTab);
	};

	/**
	 * Loads the given profile tab.
	 *
	 * @param e
	 */
	this.loadTab = function(e) {

		e.preventDefault();

		let tabID = $(this).attr('aria-controls');
		let tabContentObj = $('#' + tabID);

		tabContentObj.html('<i class="fa fa-spin fa-spinner"></i> ' + tex('global.loading_data'));
		self.fetch(tabID);
	};

	/**
	 * Gets the profiel tab HTML.
	 *
	 * @param {string} id The profile tab ID.
	 */
	this.fetch = function(id) {

		let container = $('#' + id);

		if(self.fetchingData === true) {
			if(self.jqXHR !== null) {
				self.jqXHR.abort();
				self.fetchingData = false;
			}
		}

		self.fetchingData = true;

		self.jqXHR = $.ajax({
			method: 'POST',
			url: '/ajax/profile-tab?user_name=' + self.userName,
			data: { type: id, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if (_.isObject(obj) === true) {
				if (_.has(obj, 'status') === true && _.has(obj, 'data') === true) {
					if (obj.status === 1) {
						container.html(obj.data);
						$('select').not('.select2-hidden-accessible').select2(select2DefaultOptions);
					}
					else {
						container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_STATUS)');
					}
				}
				else {
					container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
				}
			}
			else {
				container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_NO_OBJ)');
			}
		})
		.fail(function(jqXHR, textStatus, errorThrown) {
			if(errorThrown !== 'abort') {
				container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_CONN_FAIL)');
			}
		})
		.always(function() {
			self.fetchingData = false;
			self.jqXHR = null;
		});
	};


	this.init();
}