/**
 * This class provides methods to show the snackbar.
 */
class Snackbar {

	/**
	 * Shows a normal snackbar message.
	 *
	 * @param {string} msg The snackbar message.
	 */
	static message(msg) {
		this.showBar(msg, '');
	}

	/**
	 * Shows a snackbar error message.
	 *
	 * @param {string} msg The snackbar message.
	 */
	static error(msg) {
		this.showBar(msg, 'error');
	}

	/**
	 * Shows a snackbar success message.
	 *
	 * @param {string} msg The snackbar message.
	 */
	static success(msg) {
		this.showBar(msg, 'success');
	}

	/**
	 * Shows a snackbar info message.
	 *
	 * @param {string} msg The snackbar message.
	 */
	static info(msg) {
		this.showBar(msg, 'info');
	}

	/**
	 * Shows a snackbar message with the given CSS class.
	 *
	 * @param {string} msg The snackbar message.
	 * @param {string} cssClass The name of the CSS class.
	 */
	static showBar(msg, cssClass) {

		let snackbarObj = $('#snackbar');

		snackbarObj.removeClass('show');
		snackbarObj.removeClass('error');
		snackbarObj.removeClass('success');
		snackbarObj.removeClass('info');

		if(cssClass !== '') {
			snackbarObj.addClass(cssClass);
		}

		snackbarObj.html(msg);
		snackbarObj.addClass('show');

		window.setTimeout(function() {
			snackbarObj.removeClass('show');
		}, 6000);
	}
}