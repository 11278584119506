/**
 * This class provides the functions to init an shoutbox.
 */
function Shoutbox() {

	/**
	 * @type {number} The number of miliseconds until the next refresh.
	 */
	const REFRESH_INTERVAL = 15000;

	/**
	 * @type {string} The name of the shoutbox ID.
	 */
	const SHOUTBOX_ID = '#shoutbox';

	/**
	 * @type {string} The name of the container ID.
	 */
	const CONTAINER_ID = '#shoutboxContainer';

	/**
	 * @type {string} The name of the input text ID.
	 */
	const INPUT_TEXT_ID = '#shoutboxText';

	/**
	 * @type {string} The name of the input submit ID.
	 */
	const INPUT_SUBMIT_ID = '#shoutboxSubmit';

	/**
	 * @type {string} The selector of the simplebar content.
	 */
	const SHOUTBOX_SIMPLEBAR_SELECTOR = '.simplebar-scroll-content';


	/**
	 * The instance of this class.
	 *
	 * @type {Shoutbox}
	 */
	let self = this;

	/**
	 * The windows interval reference.
	 *
	 * @type {?number}
	 */
	this.interval = null;

	/**
	 * The container jQuery object.
	 *
	 * @type {?object}
	 */
	this.container = null;

	/**
	 * The input text jQuery object.
	 *
	 * @type {?object}
	 */
	this.inputText = null;

	/**
	 * The input submit jQuery object.
	 *
	 * @type {?object}
	 */
	this.inputSubmit = null;

	/**
	 * The ID of the last request.
	 *
	 * @type {?number}
	 */
	this.lastID = null;

	/**
	 * The limit of posts to display.
	 *
	 * @type {?number}
	 */
	this.limit = null;

	/**
	 * The status if an AJAX request is fetching data.
	 *
	 * @type {boolean}
	 */
	this.fetchingData = false;

	/**
	 * The status if an AJAX request is sending post data.
	 *
	 * @type {boolean}
	 */
	this.sendingPost = false;

	/**
	 * The status if an fetch call should scroll to top.
	 *
	 * @type {boolean}
	 */
	this.scrollToTopOnFetch = false;


	/**
	 * The Shoutbox class constructor.
	 */
	this.init = function() {

		this.fetch();

		self.interval = window.setInterval(self.fetch, REFRESH_INTERVAL);
		self.container = $(CONTAINER_ID);
		self.inputText = $(INPUT_TEXT_ID);
		self.inputSubmit = $(INPUT_SUBMIT_ID);
		self.limit = parseInt($('[data-shoutbox-limit]').data('shoutbox-limit'));

		self.inputSubmit.on('click', self.submitPost);
		self.inputText.on('keyup', function(e) {
			if(e.which === 13) {
				self.submitPost();
			}
		});
	};

	/**
	 * Gets the new shoutbox posts.
	 */
	this.fetch = function() {

		if(self.fetchingData === false) {
			self.fetchingData = true;

			$.ajax({
				method: 'POST',
				url: '/ajax/shoutbox',
				data: { last_id: self.lastID, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if (_.isObject(obj) === true) {
					if (_.has(obj, 'status') === true && _.has(obj, 'data') === true && _.has(obj, 'new_data') === true) {
						if (obj.status === 1) {
							if (obj.new_data === 1) {
								self.container.prepend(obj.data);
								self.lastID = obj.last_id;
								self.cleanPosts();

								if(self.scrollToTopOnFetch === true) {
									self.scrollToTopOnFetch = false;
									$(SHOUTBOX_ID).find(SHOUTBOX_SIMPLEBAR_SELECTOR).scrollTop(0);
								}
							}
						}
						else {
							self.container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_STATUS)');
						}
					}
					else {
						self.container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
					}
				}
				else {
					self.container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_NO_OBJ)');
				}
			})
			.fail(function() {
				self.container.html('<i class="fa fa-times"></i> ' + tex('error.internal_error') + ' (ERR_CONN_FAIL)');
			})
			.always(function() {
				self.fetchingData = false;
			});
		}
	};

	/**
	 * Removes posts from the view that are over defined limit.
	 */
	this.cleanPosts = function() {
		let posts = self.container.children();
		const postsCount = posts.length;

		if(postsCount > self.limit) {
			for(let i = self.limit; i < postsCount; i++) {
				posts[i].remove();
			}
		}
	};

	/**
	 * Submit a post message.
	 */
	this.submitPost = function() {

		if(self.sendingPost === false) {
			self.sendingPost = true;
			self.inputSubmit.addClass('disabled');
			self.inputSubmit.prepend('<i class="fa fa-spin fa-spinner"></i>');

			$.ajax({
				method: 'POST',
				url: '/ajax/shoutbox',
				data: { action: 'add', text: self.inputText.val(), csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if (_.isObject(obj) === true) {
					if (_.has(obj, 'status') === true) {
						if (obj.status === 1) {
							self.scrollToTopOnFetch = true;
							self.fetch();
							self.inputText.val('');
						}
						else {
							alert(obj.msg);
						}
					}
					else {
						alert(tex('error.internal_error') + ' (ERR_MISSING_ATTR)');
					}
				}
				else {
					alert(tex('error.internal_error') + ' (ERR_NO_OBJ)');
				}
			})
			.fail(function() {
				alert(tex('error.internal_error') + ' (ERR_CONN_FAIL)');
			})
			.always(function() {
				self.sendingPost = false;
				self.inputSubmit.find('i.fa').remove();
				self.inputSubmit.removeClass('disabled');
			});
		}
	};


	this.init();
}
