/**
 * This class provides the functions to init an cropper.
 *
 * @param containerID The cropper main container ID.
 */
function ImageCropper(containerID) {

	/**
	 * @type {string} The CSS class name of the choose button.
	 */
	const CHOOSE_BTN_CLASS = 'choose-file-btn';

	/**
	 * @type {string} The CSS class name of the choose file input.
	 */
	const CHOOSE_INPUT_CLASS = 'choose-file-input';

	/**
	 * @type {string} The CSS class name of the metadata input.
	 */
	const INPUT_CROPPER_METADATA = 'cropper-metadata';

	/**
	 * @type {string} The CSS class name of the cropper source image.
	 */
	const CROPPER_SRC_IMAGE = 'cropper-src-image';

	/**
	 * @type {object} The cropper options table.
	 */
	const CROPPER_OPTIONS = {
		viewMode: 1,
		aspectRatio: 1,
		movable: false,
		zoomable: false,
		zoomOnTouch: false,
		zoomOnWheel: false,
		preview: '.register-cropper-preview'
	};


	/**
	 * The instance of this class.
	 *
	 * @type {ImageCropper}
	 */
	let self = this;

	/**
	 * The jQuery object for the cropper container.
	 *
	 * @type {object}
	 */
	this.container = null;

	/**
	 * The jQuery object for the cropper select input.
	 *
	 * @type {object}
	 */
	this.selectInput = null;

	/**
	 * The jQuery object for the metadata input.
	 *
	 * @type {object}
	 */
	this.metadataInput = null;

	/**
	 * The cropper object.
	 *
	 * @type {?Cropper}
	 */
	this.cropper = null;

	/**
	 * The DOM cropper image object.
	 *
	 * @type {object}
	 */
	this.cropperImg = null;


	/**
	 * The Cropper class constructor.
   *
	 * @type {string} containerID The cropper DOM container ID.
	 */
	this.init = function(containerID) {

		self.container = $('#' + containerID);
		self.selectBtn = self.container.find('.' + CHOOSE_BTN_CLASS);
		self.selectInput = self.container.find('.' + CHOOSE_INPUT_CLASS);
		self.metadataInput = self.container.find('.' + INPUT_CROPPER_METADATA);
		self.cropperImg = self.container.find('.' + CROPPER_SRC_IMAGE)[0];
		self.cropper = new Cropper(self.cropperImg, CROPPER_OPTIONS);

		// Add event listeners to cropper object
		self.cropperImg.addEventListener('ready', self.ready);
		self.cropperImg.addEventListener('cropend', self.setInputMetadata);

		// Event handlers
		self.selectInput.on('change', self.fileSelected);
		self.selectBtn.on('click', function() {
			self.selectInput.click();
		});
	};

	/**
	 * Sets the image and cropper meta data to the input fields.
	 *
	 * @type {object} evt The file upload input reference.
	 */
	this.fileSelected = function(evt) {

		let files = evt.target.files;

		if(_.has(files, 0) === true) {
			let file = files[0];

			if(_.contains(['image/jpeg', 'image/jpg', 'image/png', 'image/gif'], file.type) === false) {
				console.log('invalid file type');
			}

			self.cropperImg.src = URL.createObjectURL(file);
			self.cropper.replace(self.cropperImg.src);
			$('#registerPreviewContainer').show();
		}
	};

	/**
	 * Sets the image and cropper meta data to the input fields.
	 */
	this.setInputMetadata = function() {
		self.metadataInput.val(JSON.stringify(self.cropper.getData(true)));
	};

	/**
	 * This event callback is fired if the cropper loading is ready.
	 */
	this.ready = function() {
		self.setInputMetadata();
		$('#registerPreviewContainer').show();
	};


	this.init(containerID);
}
