/**
 * @type {object|null} The JSON language object or Null.
 */
let langJsonObj = null;


/**
 * PHP equaliat of sprintf.
 */
String.prototype.format = function() {
	return [...arguments].reduce((p,c) => p.replace(/%s/,c), this);
};

/**
 * Gets the requested language text.
 *
 * @param {string} code The code of the language entry.
 * @param {object} params An optional object with placeholders and values.
 * @returns {string} The requested language text.
 */
function tex(code, params = {}) {

	let text = '';

	// Load JSON string if the variable is not set
	if(langJsonObj === null) {
		// noinspection JSUnresolvedVariable
		langJsonObj = JSON.parse(langJson);
	}

	if(langJsonObj.hasOwnProperty(code) === true) {
		text = decodeHtml(langJsonObj[code]);

		if(typeof params !== 'undefined' && Object.keys(params).length > 0) {
			for (const [find, replace] of Object.entries(params)) {
				text = text.replace('%' + find + '%', String(replace));
			}
		}
	}

	return (text === '') ? '[' + code + ']' : text;
}

/**
 * Convert HTML entities to their corresponding characters
 *
 * @param {string} html The input string.
 * @returns {string} Returns the decoded string.
 */
function decodeHtml(html) {
	const txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
}

/**
 * Inits the given function if the selector is available.
 *
 * @param {string} selector The selector to check.
 * @param {string} funcName The name of the function.
 */
function initFunction(selector, funcName) {
	if($(selector).length > 0) {
		funcName();
	}
}

/**
 * Inits the given class if the selector is available.
 *
 * @param {string} selector The selector to check.
 * @param {string} className The name of the class.
 */
function initClass(selector, className) {
	if($(selector).length > 0) {
		new className();
	}
}

/**
 * Sets the given title to the modal.
 *
 * @param {string} id The ID of the modal.
 * @param {string} text The title to set.
 */
function setModalTitle(id, text) {
	$('#' + id).find('.modal-title').html(text);
}

/**
 * Sets the given text to the modal.
 *
 * @param {string} id The ID of the modal.
 * @param {string} text The text to set.
 */
function setModalText(id, text) {
	$('#' + id).find('.modal-body').html(text);
}

/**
 * Sets the given error text to the modal.
 *
 * @param {string} id The ID of the modal.
 * @param {string} text The error text to set.
 */
function setModalErrorText(id, text) {
	setModalText(id, '<div class="alert alert-danger" role="alert">' + text + '</div>');
}

/**
 * Sets the data attribute to the main button of the modal dialog.
 *
 * @param {string} modalID The ID of the modal dialog.
 * @param {string} dataName The name of the data attribute.
 */
function setModalDataToPrimaryBtn(modalID, dataName) {

	$('#' + modalID).on('show.bs.modal', function(event) {
		let val = $(event.relatedTarget).data(dataName);
		$(this).find('.btn-action').data(dataName, val);
	});
}